<template>
  <div class="login-wrap relative">
    <div class="login-bg absolute"></div>
    <div class="login-inner relative">
      <h2 class="absolute text-white">{{ name }}</h2>
      <div class="login-form flex flex-end">
        <div class="login-form-inner flex justify-center flex-column">
          <h4 class="text-center">账号登录</h4>
          <div class="form-wrap">
            <div class="text-red error-msg">
              <div v-show="showError">{{errorMsg}}</div>
            </div>
            <div class="relative flex items-center border-bottom m-b-md">
              <label class="lb">用户</label>
              <a-input
                placeholder="请输入你的用户名"
                class="flex-one no-border no-bg"
                v-model="username"
              />
            </div>
            <div class="relative flex items-center border-bottom m-b-md">
              <label class="lb">密码</label>
              <div class="pass-wrap flex-one">
                <a-input
                  :type="type"
                  placeholder="请输入你的密码"
                  class="no-border no-bg"
                  v-model="password"
                  @copy="handleFalse"
                  @cut="handleFalse"
                  @paste.native.capture.prevent="handleFalse"
                />
              </div>
              <img
                src="@/assets/login/hide.png"
                class="img-open pointer"
                v-if="$g.isDepart&&!openFlag"
                @click="toggleType()"
              />
              <img
                src="@/assets/login/block.png"
                class="img-open pointer"
                v-if="$g.isDepart&&openFlag"
                @click="toggleType()"
              />
            </div>
            <div
              class="relative flex items-center border-bottom"
              v-if="$g.isDepart"
            >
              <label class="lb">验证码</label>
              <div class="pass-wrap flex-one">
                <a-input
                  placeholder="请输入你的验证码"
                  class="no-border no-bg"
                  v-model="zcode"
                  @keyup.enter="handleSubmit()"
                />
              </div>
              <div class="zcode">
                <img
                  v-if="zcodeInfo.imageBase64"
                  :src="zcodeInfo.imageBase64"
                  class="full-width full-height pointer bd-s-r bd-s-b bd-s-t bd-s-l bd-s-c"
                  @click="_getCaptcha()"
                />
              </div>
            </div>

            <div class="flex items-center justify-between re-info m-t-sm">
              <a-checkbox :checked="checked" @change="changeStatus()"
                >记住登录密码</a-checkbox
              >
              <span v-if="false">忘记密码？</span>
            </div>
            <div class="btn-wrap">
              <a-button
                type="primary"
                class="full-width"
                @click="handleSubmit()"
              >
                登录
              </a-button>
            </div>
            <div class="other-login" v-if="false">
              <span class="block text-center m-b-md">其他登录方式</span>
              <div class="flex justify-center">
                <img src="@/assets/login/person.png" class="pointer" />
                <img src="@/assets/login/wechat.png" class="pointer" />
                <img src="@/assets/login/icon-rlsb.png" class="pointer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
/* import Socket from '@/components/js/socket'; */
import { getStorage } from '@/tools';
import { CODE_OK } from '@/request/config_code'
export default {
  name: 'Login',
  data() {
    return {
      // remember: false,
      username: '',
      password: '',
      openFlag: false,
      zcodeInfo: {
        uuid: '',
        imageBase64: '',
      },
      zcode: '',
      name: '',
      errorMsg:''
    };
  },
  computed: {
    ...mapState({
      checked: (state) => state.login.checked,
    }),
    type() {
      return this.openFlag ? 'text' : 'password';
    },
    //现实错误信息
    showError() {
      return this.errorMsg != '';
    }
  },
  mounted() {
    /*  let socket = new Socket();
    socket.openSocket();
    console.error('--------', socket); */
    //记住我
    this.name = this.$g.webTitle;
    this.rememberMe();
    this._getCaptcha();
  },
  watch: {},
  methods: {
    ...mapMutations(['setCheck']),
    ...mapActions(['userLogin','userLoginDepart']),
    handleFalse() {
      return false;	
    },
    rememberMe() {
      let storage = getStorage('AM_CK_LG');
      this.username = storage.userName || '';
      this.password = storage.userPass || '';
    },
    _getCaptcha() {
      this.$api.login.getCaptcha().then((res) => {
        // console.log('---------', res);
        if (res.code === 200) {
          this.zcodeInfo.uuid = res.data.uuid;
          this.zcodeInfo.imageBase64 = `data:image/jpg;base64,${res.data.imageBase64}`;
        }
      });
    },
    toggleType() {
      this.openFlag = !this.openFlag;
    },
    changeStatus() {
      this.setCheck(!this.checked);
    },
    handleSubmit() {
      if (!this.username || !this.password) {
        // this.$message.error('请输入用户名和密码！');
        this.errorMsg = '请输入用户名和密码!';
        return;
      }/*  else if(!this.zcode) {
        this.errorMsg = '验证码不能为空!';
        return;
      } fix sxy云南软测*/
      let obj = {
        loginName: this.username,
        loginPwd: this.password,
        uuid: this.zcodeInfo.uuid,
        verificationCode: this.zcode, //fixsxy 云南软测注释
      };
       if (!this.$g.isDepart) {
        /* obj['uuid'] = this.zcodeInfo.uuid;
        obj['verificationCode'] = this.zcode; 云南软测*/
        let userNames = ['account','authority','audit'];
        if(userNames.includes(obj.loginName)) {
          window.open(
            `${window.g.jumbUrl}?username=${window.btoa(
              obj.loginName
            )}&password=${window.btoa(obj.loginPwd)}`
          );
          this.errorMsg = '';
          return;
        }
        
        //  this.userLogin(obj) //fixsxy明文
        this.userLoginDepart(obj)
        .then((res)=>{
           const {code} = res;
           if(code === CODE_OK){
             this.errorMsg = ''
           }
        })
        .catch((e) => {
          this.errorMsg = e;
          this._getCaptcha();
        });
      }else{
        this.userLoginDepart(obj).then((res)=>{
           const {code} = res;
           if(code === CODE_OK){
             this.errorMsg = ''
           }
        }).catch((e)=>{
          this.errorMsg = e
          this._getCaptcha();
        })
         
      }//fixsxy之前
      /* let userNames = ['account','authority','audit'];
      if(userNames.includes(obj.loginName)) {
          window.open(
            `${window.g.jumbUrl}?username=${window.btoa(
              obj.loginName
            )}&password=${window.btoa(obj.loginPwd)}`
          );
        return;
      }
      this.userLoginDepart(obj).then(()=>{}).catch() */
    },
  },
};
</script>

<style scoped>
.login-wrap {
  width: 100%;
  height: 100%;
  padding: 20px;
}
.login-bg {
  background: #0b1121 url('../assets/login/bg.jpg') no-repeat;
  background-size: auto 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.login-form {
  height: 100%;
}
.login-form-inner {
  width: 450px;
  height: 100%;
  background: url('../assets/login/white-bg.png') no-repeat;
  background-size: 100% 100%;
  padding: 0 80px;
}
.login-form-inner h4 {
  color: #1a1a1a;
  font-size: 28px;
  margin-bottom: 40px;
}
.login-inner {
  background: rgba(86, 100, 133, 0.1);
  border: 1px solid #506ca7;
  width: 100%;
  height: 100%;
}
.login-inner h2 {
  font-size: 36px;
  font-weight: 400;
  left: 48px;
  top: 24px;
  opacity: 0.8;
}
.no-border:focus {
  outline: none;
  border: none;
  box-shadow: none;
  background: none;
}
.zcode {
  width: 90px;
  height: 38px;
}
.border-bottom {
  border-bottom: 1px solid #d7dbe0;
  padding-bottom: 10px;
}

.border-bottom .lb {
  width: 44px;
}
.img-open {
  width: 20px;
  margin-left: 10px;
}
.btn-wrap {
  margin: 3.16rem 0;
}
.other-login img {
  width: 3.6rem;
  height: 3.6rem;
  margin: 0 2rem;
}
.login-form >>> .ant-input {
  color: #333;
}
.error-msg {
  height:32px;
  line-height:32px;
}
</style>
